import React from "react";
import styled from "styled-components";
import SocialMediaIcons from "../../shared/components/SocialMediaIcons";

const getDate = () => new Date().getFullYear();

const Footer = () => (
  <FooterContainer>
    <FooterSocialMediaIconsContainer>
      <FooterSocialMediaIcons iconColor="light" />
    </FooterSocialMediaIconsContainer>
    <FooterInfoDiv>Elle Hallal © 2018 - {getDate()}</FooterInfoDiv>
  </FooterContainer>
);

const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.color.orange};
  padding: 1.5rem 0 2rem 0;
  margin-top: 1.5rem;
`;

const FooterInfoDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  padding: 1.5rem 0 0 0;
  padding-top: 1rem;
  font-size: 0.7rem;
  text-align: center;

  @media (max-width: 768px) {
    width: 80%;
  }

  @media (max-width: 1024px) {
    width: 65%;
  }
`;

const FooterSocialMediaIconsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 768px) {
    .social-media-icons {
      text-align: center;
    }
  }

  a {
    padding: 1rem;
    font-size: 0.7rem;
    opacity: 0.8;
  }
`;
const FooterSocialMediaIcons = styled(SocialMediaIcons)`
  padding-right: 1rem;
`;

export default Footer;
