import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import HeaderBar from "../../../components/HeaderBar";

const MenuDropdown = ({ menuItems, onIconClick, className }) => {
  const classPrefix = "menu-dropdown";

  const displayDropdown = () => {
    return (
      <div className={`${classPrefix}__menu-items`}>
        {menuItems.map(menuItem => (
          <Link
            key={menuItem.name}
            to={menuItem.link}
            className={`${classPrefix}__menu-item`}
          >
            {menuItem.name}
          </Link>
        ))}
      </div>
    );
  };

  return (
    <div className={className} tabIndex={0}>
      <HeaderBar icon="close" onIconClick={onIconClick} />
      <div className={`${classPrefix}__wrapper`}>{displayDropdown()}</div>
    </div>
  );
};

const StyledMenuDropdown = styled(MenuDropdown)`
  .menu-dropdown__wrapper {
    position: absolute;
    width: 100%;
    background-color: ${props => props.theme.color.orange};
    z-index: 1;
    -webkit-box-shadow: 10px 10px 10px -1px rgba(189, 189, 189, 0.67);
    -moz-box-shadow: 10px 10px 10px -1px rgba(189, 189, 189, 0.67);
    box-shadow: 10px 10px 10px -1px rgba(189, 189, 189, 0.67);
  }

  .menu-dropdown__menu-items {
    display: flex;
    margin: 0 auto;
    width: 90%;
    flex-direction: column;
    padding: 0.5em 0;
  }

  .menu-dropdown__menu-item {
    text-decoration: none;
    font-weight: 700;
    color: black;
    text-transform: uppercase;
    font-size: 1.2rem;
    margin: 0.2em;
    padding: 0.5em 1em;
    text-align: right;
    border-radius: 5px;

    &:active,
    &:visited {
      color: ${props => props.theme.color.white};
    }

    &:hover {
      color: ${props => props.theme.color.orange};
      background-color: ${props => props.theme.color.lightOrange};
    }
  }

  .menu-dropdown__menu-item:after {
    content: "";
    display: block;
    margin: 0 auto;
    border-bottom: 1px solid ${props => props.theme.color.lightOrange};
  }
`;

export default StyledMenuDropdown;
