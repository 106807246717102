import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";

import { useSiteMetadata } from "../../../hooks/useSiteMetadata";

const SocialMediaIcons = ({ className, iconColor = "dark" }) => {
  const classPrefix = "social-media-icons";
  const { socialMediaLinks } = useSiteMetadata();

  const iconPrefix = name => (["Email", "RSS"].includes(name) ? "fas" : "fab");

  const getSocialMediaIcons = socialMediaLinks => {
    return socialMediaLinks.map(data => {
      const { name, link, iconName } = data;
      return (
        <SocialMediaLink
          className={`${classPrefix}__links ${iconColor}`}
          key={name}
          href={link}
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon
            key={name}
            alt={name}
            icon={[iconPrefix(name), iconName]}
            size="lg"
            fixedWidth
          />
        </SocialMediaLink>
      );
    });
  };

  return (
    <div className={`${className} ${classPrefix}`}>
      {getSocialMediaIcons(socialMediaLinks)}
    </div>
  );
};

const StyledSocialMediaIcons = styled(SocialMediaIcons)`
  .dark {
    color: ${props => props.theme.color.orange};
    &:active,
    &:visited {
      color: ${props => props.theme.color.orange};
    }
    &:hover {
      color: ${props => props.theme.color.grey};
    }
  }

  .light {
    color: ${props => props.theme.color.white};
    &:active,
    &:visited {
      color: ${props => props.theme.color.white};
    }
    &:hover {
      color: ${props => props.theme.color.black};
    }
  }

  .dark,
  .light {
    margin-right: 0.5em;
    font-size: 1em;
  }
`;

const SocialMediaLink = styled.a`
  color: ${props => props.theme.color.orange};
  &:active,
  &:visited {
    color: ${props => props.theme.color.orange};
  }
  &:hover {
    color: ${props => props.theme.color.grey};
  }
  margin-right: 0.5em;
  font-size: 1em;
`;

export default StyledSocialMediaIcons;
