import { graphql, useStaticQuery } from "gatsby";

export const useSiteMetadata = () => {
  const { site } = useStaticQuery(
    graphql`
      query SITE_METADATA_QUERY {
        site {
          siteMetadata {
            description
            title
            siteUrl
            siteLanguage
            siteLocale
            twitterUsername
            twitterUsernameShort
            twitterURL
            authorName
            menuItems {
              name
              link
            }
            socialMediaLinks {
              name
              link
              iconName
            }
          }
        }
      }
    `
  );

  return site.siteMetadata;
};
