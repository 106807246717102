import React from "react";
import Helmet from "react-helmet";
import styled, { ThemeProvider } from "styled-components";
import theme from "../../assets/js/theme";
import "@fortawesome/fontawesome-svg-core/styles.css";

import Header from "../Header";
import Footer from "../Footer";
import registerIcons from "../../registerIcons";

registerIcons();

const Layout = ({ title, siteUrl, slug, children }) => {
  const classPrefix = "layout";
  return (
    <ThemeProvider theme={theme}>
      <Helmet title={title}>
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:image"
          content={`${siteUrl}${slug}twitter-card.jpg`}
        />
      </Helmet>
      <StyledLayout>
        <Header />
        <div className={`${classPrefix}__main-container`}>{children}</div>
        <Footer />
      </StyledLayout>
    </ThemeProvider>
  );
};

const StyledLayout = styled.main`
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  .layout__main-container {
    flex-grow: 1;
  }

  header,
  .main-container,
  footer {
    flex-shrink: 0;
  }
`;

export default Layout;
