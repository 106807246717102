const theme = {
  color: {
    lighterGrey: "#F4F4F4",
    lightGrey: "#dbdbdb",
    mediumGrey: "#f3f3f3",
    grey: "grey",
    darkGrey: "#7f777e",
    lightOrange: "#FFEACC",
    orange: "#ff9a35",
    black: "black",
    white: "white",
    cream: "#f5f3f2",
    defaultText: "#474747"
  },
  typography: {
    fontFamily: "Open Sans, sans-serif"
  },
  backgroundImages: {}
};

export default theme;
