import { Link } from "gatsby";
import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const HeaderBar = ({ icon, onIconClick }) => {
  const displayNavOpenIcon = () => (
    <FontAwesomeIcon
      alt="tag icon"
      icon={["fas", "bars"]}
      size="lg"
      onClick={() => onIconClick()}
      fixedWidth
    />
  );

  const displayCloseIcon = () => (
    <FontAwesomeIcon
      alt="tag icon"
      icon={["fas", "times"]}
      size="lg"
      onClick={() => onIconClick()}
      fixedWidth
    />
  );

  return (
    <HeaderBarContainer>
      <HeaderBarInnerDiv>
        <SiteTitleLink to="/">
          <div className="header-bar__site-title">
            <span
              role="img"
              aria-label="Female emoji behind a laptop"
              className="HeaderBar-emoji"
            >
              👩🏽‍💻
            </span>{" "}
            <div className={`header-bar__site-title-text`}>Elle Hallal</div>
          </div>
        </SiteTitleLink>
        <HeaderBarNavContainer>
          <div className={`header-bar__menu-dropdown`}>
            {icon === "open" && displayNavOpenIcon()}
            {icon === "close" && displayCloseIcon()}
          </div>
        </HeaderBarNavContainer>
      </HeaderBarInnerDiv>
    </HeaderBarContainer>
  );
};

const SiteTitleLink = styled(Link)`
  text-decoration: none;
  font-size: 0.7rem;
`;

const HeaderBarNavContainer = styled.div`
  display: flex;
  align-items: right;
  justify-content: space-between;
  cursor: pointer;

  svg {
    padding: 0.5em;
  }
`;

const HeaderBarInnerDiv = styled.div`
  display: flex;
  justify-content: space-between;
  width: 90%;
  margin: 0 auto;
`;

const HeaderBarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  background-color: ${(props) => props.theme.color.orange};
  color: ${(props) => props.theme.color.lightOrange};
  padding: 0.5em 0;

  a {
    color: ${(props) => props.theme.color.white};
  }

  .HeaderBar-emoji {
    font-size: 1.7rem;
    margin-right: 1em;
  }

  .header-bar__site-title {
    display: flex;
    flex-direction: row;
  }

  .header-bar__site-title-text {
    align-self: center;
    font-size: 1.5em;
    text-transform: uppercase;
    font-weight: 600;
  }
`;

export default HeaderBar;
