import React, { useState } from "react";
import styled from "styled-components";
import MenuDropdown from "../../shared/components/MenuDropdown";
import { useSiteMetadata } from "../../hooks/useSiteMetadata";
import HeaderBar from "../HeaderBar";

const Header = () => {
  const menuItems = useSiteMetadata().menuItems;
  const [showDropdown, setShowDropdown] = useState(false);

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  return (
    <HeaderContainer>
      {!showDropdown && <HeaderBar icon="open" onIconClick={toggleDropdown} />}
      {showDropdown && (
        <MenuDropdown menuItems={menuItems} onIconClick={toggleDropdown} />
      )}
    </HeaderContainer>
  );
};

const HeaderContainer = styled.div``;

export default Header;
